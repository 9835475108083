.header {
  background: whitesmoke;
  border-bottom: 1px solid #e8e8e8;
  top: 0;
  left: 0;
  right: 0;

  &-label {
    height: 2.5rem;
    display: flex;
    align-items: center;

    &-logo {
      width: 1.7rem;
      height: 1.7rem;
      margin-right: 0.75rem;
    }

    &-name {
      font-size: 1.1rem;
      font-weight: 500;
      margin-right: 1rem;
    }
  }

  &-logout {
    display: flex;

    &-button {
      font-size: 1.25rem;
      cursor: pointer;
    }
  }

  &-username {
    padding-right: 1rem;
  }

  &-wrapper {
    @extend .wrapper;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: flex;
    }

    .ant-menu {
      border-bottom: none;
    }
  }
}

@media (max-width: 1000px) {
  .header {
    width: max-content;
  }
}

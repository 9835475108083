.login {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;

  &-page {
    width: 30rem;
    min-width: 20rem;
    padding: 10vh 1rem 1rem;
    height: 100%;


    &-logo-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    &-label {
      font-weight: 600;
      margin: 0.5rem 0 2.5rem;
      font-size: 1.25rem;
    }

    &-logo {
      height: 4rem;
      width: 4rem;
    }

    .ant-form {
      width: 100%;

      .ant-col {
        width: 100%;
      }
    }

    &-button {
      width: 100%;
    }
  }

  .ant-input-affix-wrapper {
    display: flex;
  }
}
.setup-second-factor {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  &-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 30rem;
    min-width: 20rem;
    padding: 10vh 1rem 1rem;
    height: 100%;

    &-qr {
      height: 20rem;
      width: 20rem;
      margin-bottom: 1rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 4rem;
  border-top: 1px solid grey;
  user-select: none;
  color: #333;
  background: #f2f2f2;

  &-wrapper {
    @extend .wrapper;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }

  &-image {
    height: 2rem;
    width: 2rem;
    margin: 0 0.5rem;
  }
}

@media (max-width: 1000px) {
  .footer {
    width: max-content;
  }
}

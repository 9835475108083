@import "src/styles/_login.scss";
@import "src/styles/_secondFactor.scss";
@import "src/styles/_setupSecondFactor.scss";
@import "src/styles/_secondFactorForm.scss";
@import "src/styles/_header.scss";
@import "src/styles/_footer.scss";

.wrapper {
  max-width: 1700px;
  min-width: 1000px;
  margin: 0 auto;
  padding: 0 1rem;
}

.ignite-content {
  width: 100%;

  .main {
    @extend .wrapper;
    width: 100%;
    height: calc(100vh - 6.6rem);

    .page-title {
      font-size: 1.1rem;
      padding-top: 2rem;
      margin: 0 1rem 2rem 1rem;
    }

    .ant-table-cell {
      font-size: 12px;
    }
  }
}
.second-factor-code-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    display: flex;
  }

  &-button {
    width: 65px;
    margin-left: 1rem;
  }

}